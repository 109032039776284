import React from "react"
import Helmet from "react-helmet"
import Layout from "../../../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import JumboPhoto from "../../../components/jumbophoto"

export default () => <Layout>
	<Helmet title="About Automatic Stay | Gina Mcdonald &amp; Associates, L.L.C." defer={false} />

	<JumboPhoto/>

	<Container>
		<Row>
			<Col>
				<h1>What is the Automatic Stay?</h1>
				<hr />
				<p>Perhaps the greatest weapon that the Bankruptcy Code allowed to Debtors is the Automatic Stay. With certain exceptions, the Automatic Stay goes into effect upon the filing of a Bankruptcy Case, and is a restraining order that prevents creditors from initiating or continuing any action to collect a debt or enforce rights against property owned by the Debtor. A creditor that takes any action to collect a pre-petition debt against the Debtor or property of the debtor could be subject to punishment by the Bankruptcy Court.</p>

				<p>Simply by filing a Bankruptcy Case, you can stop most of your creditors from initiating or continuing lawsuits, sending harassing collection letters, or making annoying collection calls. The automatic stay stops most creditors from garnishing your wages, from recording judgment liens against your property, from repossessing property that you own, and trying to foreclose a mortgage against real estate that you own, while you are under the protection of the Bankruptcy Court.</p>
			</Col>
		</Row>
	</Container>
</Layout>